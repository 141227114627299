// extracted by mini-css-extract-plugin
export const heading_container = "services-module--heading_container--9c5ij";
export const sub_heading = "services-module--sub_heading--1ubG_";
export const heading = "services-module--heading--JD4ca";
export const text_heading = "services-module--text_heading--2H4Hc";
export const big_grid = "services-module--big_grid--2Cij_";
export const container = "services-module--container--2djVS";
export const double_container = "services-module--double_container--2TTMZ";
export const container_title = "services-module--container_title--1pxwH";
export const big_container_title = "services-module--big_container_title--b9utz";
export const inegal_container = "services-module--inegal_container--qkdtj";
export const orange_gradient = "services-module--orange_gradient--2s8u4";
export const red_gradient = "services-module--red_gradient--3qXv6";
export const blue_gradient = "services-module--blue_gradient--3f77X";
export const cyan_gradient = "services-module--cyan_gradient--2kRBK";
export const violet_gradient = "services-module--violet_gradient--3_cgS";
export const purple_gradient = "services-module--purple_gradient--2X41l";
export const grey = "services-module--grey--3Y6Jk";
export const dark_gradient = "services-module--dark_gradient--1aAKC";
export const normal_text = "services-module--normal_text--ztgju";
export const sub_heading_services = "services-module--sub_heading_services--2Wc67";
export const bloc_intermediaire = "services-module--bloc_intermediaire--20Xh5";
export const black_heading = "services-module--black_heading--3E7LD";
export const contact_email = "services-module--contact_email--vUGkD";
export const contact_btn = "services-module--contact_btn--2dffZ";
export const project_grid = "services-module--project_grid--2gGYI";
export const project = "services-module--project--lWRRf";
export const project_btn = "services-module--project_btn--3XICw";