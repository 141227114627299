import React, { Component } from "react";
import Navbar from "../components/navbar";
import * as servicesStyles from "../styles/css/services.module.css";
import Appli from "../styles/images/code.svg";
import Shop from "../styles/images/online-shop.svg";
import Website from "../styles/images/web-site.svg";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class Services extends Component {
    render(){
        return (
            <React.Fragment>
                <Navbar color="orange"/>
                <div className={"page_content"}>
                    <section className={servicesStyles.heading_container}>
                        <span className={servicesStyles.sub_heading}>Nos incroyables services</span>
                        <h1 className={servicesStyles.heading}>Site vitrine, Boutique en ligne, Application web.</h1>
                        <p className={servicesStyles.text_heading}>
                            Du graphisme à la mise en ligne en passant par la conception et le développement. 
                            Nous nous occupons de toutes les étapes de votre projet web
                        </p>
                    </section>
                    <section className={servicesStyles.big_grid}>
                        <div className={servicesStyles.container + " " + servicesStyles.inegal_container + " " + servicesStyles.orange_gradient}>
                            <h2 className={servicesStyles.big_container_title}>Faites rayonner votre marque sur le web.</h2>
                        </div>
                        <div className={servicesStyles.container + " " + servicesStyles.blue_gradient}>
                            <h2 className={servicesStyles.container_title}>Notre but ? <br/> Vous aider !</h2>
                            <p className={servicesStyles.normal_text}>
                                Notre philosophie est de réaliser quelque chose qui vous ressemble et qui vous représente.
                                WDS a été créé dans le but de fournir à ses clients la vitrine numérique qu'ils méritent et qui 
                                met en valeur leur travail exceptionnel.
                            </p>
                        </div>
                    {/*</section>
                    <section className={servicesStyles.triple_container}>*/}
                            <div className={servicesStyles.container + " " + servicesStyles.grey}>
                                <span  className={servicesStyles.sub_heading_services}>Site vitrine</span>
                                <h2 className={servicesStyles.container_title}>Gagnez en visibilité !</h2>
                                <p className={servicesStyles.normal_text}>
                                    Comme son nom l'indique, un site vitrine est votre vitrine numérique. 
                                    Il vous sert à présenter votre activité, votre savoir-faire ou encore vos réalisations.
                                    Autant dire qu'un site vitrine est tout bonnement indispensable !
                                </p>
                            </div>
                            <div className={servicesStyles.container + " " + servicesStyles.cyan_gradient}>
                                <span className={servicesStyles.sub_heading_services}>Boutique en ligne</span>
                                <h2 className={servicesStyles.container_title}>Entrez dans <br/> le E-commerce !</h2>
                                <p className={servicesStyles.normal_text}>
                                    Ces derniers temps, les ventes en lignes ont tout simplement explosées et la tendance 
                                    n'est pas prête de s'inverser... Vous aussi vous avez des produits à vendre ? Vous 
                                    vous dites qu'il vous faut un site de qualité pour vous démarquer ? Ne chercher plus !
                                    Nous sommes là pour rendre cela possible !
                                </p>
                            </div>
                            <div className={servicesStyles.container + " " + servicesStyles.red_gradient}>
                                <span className={servicesStyles.sub_heading_services}>Application web</span>
                                <h2 className={servicesStyles.container_title}>Votre solution métier !</h2>
                                <p className={servicesStyles.normal_text}>
                                    Parce que vous êtes unique, vous avez un besoin unique ? Un projet qui vous permettrait de vous faire
                                    gagner un temps précieux et ainsi vous concentrez sur votre métier ? Aucun problème !
                                    Notre savoir faire dans le développement web nous permet de développer des solutions 
                                    répondant aux besoins uniques de nos clients.
                                </p>
                            </div>
                    </section>
                    <section className={servicesStyles.bloc_intermediaire}>
                        <h2 className={servicesStyles.black_heading}>Notre matière grise à votre disposition.</h2>
                        <p className={servicesStyles.text_heading}>Chez WDS, les défis nous stimulent. Vous avez une demande particulière ? Nous vous trouverons
                            la solution la plus adaptée et qui vous satisfasse pleinement.
                        </p>
                    </section>
                    <section>
                        <div className={servicesStyles.container + " " + servicesStyles.purple_gradient}>
                            <h2 className={servicesStyles.big_container_title}>Montrez à quel point vous êtes exceptionnel.</h2>
                            <p className={servicesStyles.normal_text}>
                                Vous le savez bien, nous sommes plus que jamais à l'heure du numérique. C'est pourquoi la majorité
                                de vos prospects ont pris le réflexe d'aller voir sur internet votre travail. Vous n'avez pas de site internet ?
                                Autant dire que vous êtes invisible aux yeux de nombreux prospects ! Vous avez une site internet vieillissant ? 
                                Un site web vieillissant ne reflète malheureusement pas la qualité de votre travail ou la qualité des produits
                                que vous souhaitez vendre... Développer des sites web qui reflètent la qualité de votre travail est notre métier.
                                Laissez-nous vous accompagner dans votre projet et montrez à vos prospects à quel point votre travail est exceptionnel !
                            </p>
                        </div>
                    </section>
                    <section className={servicesStyles.double_container}>
                        <div className={servicesStyles.container + " " + servicesStyles.violet_gradient}>
                            <h2 className={servicesStyles.container_title}>Découvrez nos derniers projets.</h2>
                            <div className={servicesStyles.project_grid}>
                                <div className={servicesStyles.project}>
                                    <img src={Website}></img>
                                    <h3>Site vitrine</h3>
                                    <AniLink paintDrip color="purple" to="/projets/ads">Découvrir</AniLink>
                                </div>
                                <div className={servicesStyles.project}>
                                    <img src={Shop}></img>
                                    <h3>E-commerce</h3>
                                    <AniLink paintDrip color="purple" to="/projets/idsucre">Découvrir</AniLink>
                                </div>
                                <div className={servicesStyles.project}>
                                    <img src={Appli}></img>
                                    <h3>Application</h3>
                                    <AniLink paintDrip color="purple" to="/projets/qwaris">Découvrir</AniLink>
                                </div>
                            </div>
                            <AniLink paintDrip color="purple" to="/projets/"className={servicesStyles.project_btn}>Découvrir toutes nos réalisations</AniLink>
                        </div>
                        <div className={servicesStyles.container + " " + servicesStyles.dark_gradient}>
                            <h2 className={servicesStyles.container_title}>Un projet ? <br/> Contactez-nous !</h2>
                            <p className={servicesStyles.normal_text}>
                                Envoyez-nous un petit mail pour nous expliquer votre projet. 
                                Nous serons ravis de vous répondre et de vous accompagner dans la réalisation de celui-ci.
                            </p>
                            <p className={servicesStyles.normal_text + " " + servicesStyles.contact_email}>
                                Email : contact@agencewds.fr
                            </p>
                            <AniLink paintDrip color="purple" to="/contact/" className={servicesStyles.contact_btn} href="#">Contactez-nous</AniLink>
                        </div>
                    </section>
                </div>
            </React.Fragment>  
        )
    }
}

export default Services;